.separator {
    display: flex;
    align-items: center;
    text-align: center;
}

.separator::before,
.separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid rgba(145, 158, 171, 0.32);
}

.separator:not(:empty)::before {
    margin-right: 0.25em;
}

.separator:not(:empty)::after {
    margin-left: 0.25em;
}

/* Calendar popup see more custom */
.fc-popover-body {
    max-height: 150px;
    overflow-y: scroll;
}

html{
    scroll-behavior: smooth;
}